import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, isEqual } from 'lodash';
import classNames from 'classnames';
import InputText from '../input-text';
import CurrentUserAvatar from '../../containers/current-user-avatar';
import Button from '../button';
import RichContentEditor from '../rich-content-editor-async';
import { getIsAutoSuggestionsEnabled } from '../../selectors/app-settings-selectors';
import postFormSettings from '../../services/post-form-settings';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import { withFastForm } from '../../../common/components/fast-form';
import { connect } from '../../../common/components/runtime-context';
import { POST_FORM_TITLE_REQUIRED, POST_FORM_UPLOAD_IN_PROGRESS } from '../messages/message-types';
import PostAutoSuggestions from '../post-auto-suggestions';
import getTheme from '../rich-content-editor/theme-post-form';
import { EXPERIMENT_NEW_QUESTION_PLACEHOLDER } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withExperiment from '../../hoc/with-experiment';

import styles from './post-form-desktop.scss';
import rceTheme from '../rich-content-editor/theme.scss';
import { getQuestionPlaceholder } from './post-form-common';

export class PostForm extends Component {
  constructor(props) {
    super(props);
    const { content, ...values } = props.fastForm.values;

    this.state = {
      initialValues: values,
      initialContent: content,
      isLocalSuggestionsEnabled: true,
      isTitleInputFocused: false,
    };
  }
  componentDidMount() {
    if (this.titleInputRef) {
      this.titleInputRef.addEventListener('focus', this.handleInputFocus);
      this.titleInputRef.addEventListener('blur', this.handleInputBlur);
    }
  }

  componentDidUnmount() {
    if (this.titleInputRef) {
      this.titleInputRef.removeEventListener('focus', this.handleInputFocus);
      this.titleInputRef.removeEventListener('blur', this.handleInputBlur);
    }
  }

  handleInputFocus = () => this.setState({ isTitleInputFocused: true });

  handleInputBlur = () => this.setState({ isTitleInputFocused: false });

  handleDrop = event => event.preventDefault();

  handleCancelClick = () => {
    const { content, ...rest } = this.props.fastForm.values;
    const { initialValues, initialContent } = this.state;
    this.props.onCancel(!isEqual(rest, initialValues) || !isEqual(content, initialContent));
  };

  handleToggleSuggestions = () => {
    const newSuggestionsValue = !this.state.isLocalSuggestionsEnabled;

    this.props.toggleSuggestionsClicked(newSuggestionsValue);
    this.setState({ isLocalSuggestionsEnabled: newSuggestionsValue });
    this.titleInputRef && this.titleInputRef.focus();
  };

  onContentChange = value => {
    if (!this.state.initialContent) {
      this.setState({ initialContent: value });
    }
    this.props.fastForm.changeValue('content')(value);
  };

  renderAvatar() {
    return <CurrentUserAvatar type={CurrentUserAvatar.POST_PAGE} showUserInfoInline />;
  }

  renderTextEditor() {
    const { fastForm, postType, t, isNewQuestionPlaceholderEnabled } = this.props;
    return (
      <div className={rceTheme.richContentEditor}>
        <RichContentEditor
          placeholder={t(
            isDiscussion(postType)
              ? 'text-editor.placeholder'
              : getQuestionPlaceholder(isNewQuestionPlaceholderEnabled, t),
          )}
          initialState={fastForm.values.content || undefined}
          onChange={this.onContentChange}
          externalModalsEnabled
          origin="post"
          themeGetter={getTheme}
        />
      </div>
    );
  }

  render() {
    const {
      postId,
      onSubmitButtonClick: handleSubmitButtonClick,
      t,
      borderWidth,
      contentFontClassName,
      titleFontClassName,
      fastForm: { errors, values, changeValue, isSubmitting, isValid },
      forPublicUser,
      showCategorySelect,
      postType,
      showMessage,
      isAutoSuggestionsEnabled,
    } = this.props;
    const { isLocalSuggestionsEnabled, isTitleInputFocused } = this.state;
    const titleValue = values.title || '';
    const onTitleChange = value => changeValue('title')(value);
    const containerClassName = classNames(styles.form, 'forum-text-color', contentFontClassName);
    const isButtonDisabled = isSubmitting || !isValid;
    let buttonTooltipText;
    let a11yHandler;

    if (errors.title) {
      buttonTooltipText = t('post-form.require-title');
      a11yHandler = () => {
        showMessage(POST_FORM_TITLE_REQUIRED);
        this.titleInputRef && this.titleInputRef.focus();
      };
    } else if (errors.content) {
      a11yHandler = () => showMessage(POST_FORM_UPLOAD_IN_PROGRESS);
      buttonTooltipText = t('post-form.uploading');
    }
    const isDiscussionType = isDiscussion(postType);
    const shouldShowSuggestions = isAutoSuggestionsEnabled && titleValue.length !== 0;
    return (
      <div
        className={classNames(
          containerClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        <div
          className={classNames(styles.mainArea, 'forum-card-border-color')}
          style={{ borderWidth }}
        >
          <div className={styles.header}>{this.renderAvatar()}</div>
          <div className={styles.titleInputWrapper}>
            <InputText
              value={titleValue}
              onChange={onTitleChange}
              containerClassName={titleFontClassName}
              className={classNames(styles.titleInput, 'forum-text-color')}
              maxLength={140}
              aria-label={t('post-form.title')}
              placeholder={t(
                isDiscussionType
                  ? 'post-form.give-this-post-a-title'
                  : 'post-form.your-question-here',
              )}
              onDrop={this.handleDrop}
              data-hook="post-form__title-input"
              inputRef={r => (this.titleInputRef = r)}
              autoFocus
            />
          </div>
          {shouldShowSuggestions && (
            <PostAutoSuggestions
              title={titleValue}
              handleDisableSuggestions={this.handleToggleSuggestions}
              isTitleInputFocused={isTitleInputFocused}
              isLocalSuggestionsEnabled={isLocalSuggestionsEnabled}
            />
          )}
          {!isLocalSuggestionsEnabled && (
            <button
              className={classNames(styles.textButton, 'button-color')}
              onClick={this.handleToggleSuggestions}
              data-hook="turn-on-suggestions"
            >
              {t('post-auto-suggestions.turn-on-suggestions')}
            </button>
          )}
          <div className={classNames(styles.content, 'post-form__text-editor')}>
            {this.renderTextEditor()}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.editorControls} />
          <div className={styles.buttons}>
            <span className={styles.button}>
              <Button isSecondary onClick={this.handleCancelClick} isSmall>
                {t('post-form.cancel')}
              </Button>
            </span>
            <span className={styles.button}>
              <Button
                onClick={
                  !isButtonDisabled
                    ? forPublicUser(() =>
                        handleSubmitButtonClick(() => this.props.fastForm.submit()),
                      )
                    : forPublicUser(a11yHandler)
                }
                tooltipText={buttonTooltipText}
                isLoading={isSubmitting}
                data-hook="post-form__publish-button"
                aria-label={isSubmitting ? t('post-form.submitting') : ''}
                isSmall
              >
                {showCategorySelect
                  ? t('post-form.next')
                  : postId
                  ? t('post-form.update')
                  : t('post-form.publish')}
              </Button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

PostForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func,
  postId: PropTypes.string,
  categoryId: PropTypes.string,
  showCategorySelect: PropTypes.bool,
  forPublicUser: PropTypes.func,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object,
  postType: PropTypes.string,
  isAutoSuggestionsEnabled: PropTypes.bool,
  toggleSutggestionsClicked: PropTypes.func,
  isNewQuestionPlaceholderEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  showMessage: actions.showMessage,
  isAutoSuggestionsEnabled: getIsAutoSuggestionsEnabled(state, host.style),
  toggleSuggestionsClicked: status =>
    actions.buttonClicked({
      name: 'toggle_suggestions',
      isEnabled: status,
      categoryId: ownProps.categoryId,
    }),
});

export default flowRight(
  withFastForm(postFormSettings),
  connect(mapRuntimeToProps),
  withTranslate,
  withCardBorderWidth,
  withFontClassName,
  withAuth,
  withExperiment({ isNewQuestionPlaceholderEnabled: EXPERIMENT_NEW_QUESTION_PLACEHOLDER }),
)(PostForm);
