import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { ShareIcon } from '../../components/icons/share-icon';
import { ReportIcon } from '../../components/icons/report-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { triggerIosKeyboard } from '../../services/ios-utils';
import ActionButton from '../../components/action-button';
import { MODAL_TYPE_SHARE_COMMENT } from '../../components/modals/share-comment-modal/share-comment-modal-type';
import { MODAL_TYPE_REPORT_COMMENT } from '../../components/modals/report-comment-modal/report-comment-modal-type';
import { MODAL_TYPE_DELETE_COMMENT } from '../../components/modals/delete-comment-modal/delete-comment-modal-type';
import { MODAL_TYPE_EDIT_COMMENT } from '../../components/modals/comment-edit-modal/comment-edit-modal-type';
import { isPreview } from '../../../common/store/basic-params/basic-params-selectors';
import { getPost } from '../../selectors/post-selectors';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withPermissions from '../../hoc/with-permissions';
import withDeviceType from '../../hoc/with-device-type';
import withAuth from '../../hoc/with-auth';
import { SolvedIcon } from '../../components/icons/solved-icon';
import withExperiment from '../../hoc/with-experiment';
import {
  EXPERIMENT_NEW_QA_KEYS,
  EXPERIMENT_POSTPAGE_REVAMP,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { openNativeShareIfSupported } from '../../../common/services/native-share';
import { getSectionUrl } from '../../../common/store/location/location-selectors';

export class CommentActions extends Component {
  showShare = () => {
    return !this.props.isEditor && !this.props.isPreview;
  };

  openModal = type => {
    return () => {
      const {
        buttonClicked,
        openModal,
        focusOnCloseId,
        category,
        post,
        comment,
        isReply,
      } = this.props;
      buttonClicked({ action: type });

      return openModal(
        type,
        {
          categoryId: category._id,
          categorySlug: category.slug,
          postId: post._id,
          postSlug: post.slug,
          postType: post.postType,
          isReply,
          commentId: comment._id,
        },
        { focusOnCloseId },
      );
    };
  };

  handleEdit = () => {
    const { isMobile, startEditingComment, comment, isPostPageRevampEnabled } = this.props;

    if (isMobile) {
      isPostPageRevampEnabled && triggerIosKeyboard();
      this.openModal(MODAL_TYPE_EDIT_COMMENT)();
    } else {
      startEditingComment(comment._id);
    }
  };

  handleAddBestAnswer = () => {
    const { post, comment, markAsBestAnswerClicked, addBestAnswer } = this.props;
    markAsBestAnswerClicked({
      isMarked: true,
      commentId: comment._id,
    });
    addBestAnswer(post._id, comment._id);
  };

  handleRemoveBestAnswer = () => {
    const { post, markAsBestAnswerClicked, removeBestAnswer, comment } = this.props;
    markAsBestAnswerClicked({ isMarked: false, commentId: comment._id });
    removeBestAnswer(post._id);
  };

  componentDidMount() {
    this.props.actionsOpened({ type: 'comment' });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: 'comment' });
  }

  shareComment = () => {
    const { comment, sectionUrl } = this.props;
    openNativeShareIfSupported(
      {
        url: `${sectionUrl}/main/comment/${comment._id}`,
      },
      this.openModal(MODAL_TYPE_SHARE_COMMENT),
    );
  };
  render() {
    const {
      canRender,
      comment,
      t,
      forPublicUser,
      showEditButton,
      showBestAnswerButton,
      isReply,
      isDiscussion,
      isNewQAKeysEnabled,
    } = this.props;
    const shareButton = canRender([['read', 'comment', comment]], () => (
      <ActionButton dataHook="comment-actions__share" onClick={this.shareComment}>
        <ShareIcon />
        {t(
          isReply
            ? !isDiscussion && isNewQAKeysEnabled
              ? 'comment-actions.question.share-reply'
              : 'comment-actions.share-reply'
            : isDiscussion
            ? 'comment-actions.share-comment'
            : 'comment-actions.share-answer',
        )}
      </ActionButton>
    ));
    const reportButton = canRender(
      [
        ['report', 'comment', comment],
        ['read', 'comment', comment],
      ],
      ([canReport]) => (
        <ActionButton
          dataHook="comment-actions__report"
          onClick={
            canReport
              ? this.openModal(MODAL_TYPE_REPORT_COMMENT)
              : forPublicUser(this.openModal(MODAL_TYPE_REPORT_COMMENT))
          }
        >
          <ReportIcon />
          {t(
            isReply
              ? !isDiscussion && isNewQAKeysEnabled
                ? 'comment-actions.question.report-reply'
                : 'comment-actions.report-reply'
              : isDiscussion
              ? 'comment-actions.report-comment'
              : 'comment-actions.report-answer',
          )}
        </ActionButton>
      ),
    );
    const editButton = canRender([['edit', 'comment', comment]], () => (
      <ActionButton
        dataHook="comment-actions__edit"
        onClick={forPublicUser(this.handleEdit)}
        className="comment-actions__edit"
      >
        <EditIcon />
        {t(
          isReply
            ? !isDiscussion && isNewQAKeysEnabled
              ? 'comment-actions.question.edit-reply'
              : 'comment-actions.edit-reply'
            : isDiscussion
            ? 'comment-actions.edit-comment'
            : 'comment-actions.edit-answer',
        )}
      </ActionButton>
    ));
    const deleteButton = canRender([['delete', 'comment', comment]], () => (
      <ActionButton
        dataHook="comment-actions__delete"
        onClick={forPublicUser(this.openModal(MODAL_TYPE_DELETE_COMMENT))}
      >
        <DeleteIcon />
        {t(
          isReply
            ? !isDiscussion && isNewQAKeysEnabled
              ? 'comment-actions.question.delete-reply'
              : 'comment-actions.delete-reply'
            : isDiscussion
            ? 'comment-actions.delete-comment'
            : 'comment-actions.delete-answer',
        )}
      </ActionButton>
    ));
    return (
      <div>
        {this.showShare() && shareButton}
        {showBestAnswerButton && !isDiscussion && this.renderBestAnswerButton()}
        {reportButton}
        {showEditButton && editButton}
        {deleteButton}
      </div>
    );
  }

  renderBestAnswerButton() {
    const { canRender, post, comment, t } = this.props;
    if (post.bestAnswerCommentId !== comment._id) {
      return canRender([['add-best-answer-comment', 'post', post]], () => (
        <ActionButton
          onClick={this.handleAddBestAnswer}
          dataHook="comment-actions__add-best-answer"
        >
          <SolvedIcon />
          {t('comment-actions.mark-as-solved')}
        </ActionButton>
      ));
    }

    return canRender([['remove-best-answer-comment', 'post', post]], () => (
      <ActionButton
        onClick={this.handleRemoveBestAnswer}
        dataHook="comment-actions__remove-best-answer"
      >
        <SolvedIcon />
        {t('comment-actions.unmark-as-solved')}
      </ActionButton>
    ));
  }
}

CommentActions.defaultProps = {
  showEditButton: true,
  showBestAnswerButton: true,
};

CommentActions.propTypes = {
  comment: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  openModal: PropTypes.func,
  startEditingComment: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  actionsOpened: PropTypes.func.isRequired,
  actionsClosed: PropTypes.func.isRequired,
  addBestAnswer: PropTypes.func.isRequired,
  removeBestAnswer: PropTypes.func.isRequired,
  canRender: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  navigateWithinForum: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  focusOnCloseId: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  showEditButton: PropTypes.bool,
  showBestAnswerButton: PropTypes.bool,
  isEditor: PropTypes.bool,
  isPreview: PropTypes.bool,
  isReply: PropTypes.bool,
  isDiscussion: PropTypes.bool,
  sectionUrl: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const comment = ownProps.comment;
  const post = comment.post || getPost(state, comment.postId);
  const category = getCategory(state, post.categoryId);
  return {
    category,
    post,
    isPreview: isPreview(state),
    isReply: comment.parentId !== undefined,
    isDiscussion: isDiscussion(post.postType),
    openModal: actions.openModal,
    startEditingComment: actions.startEditingComment,
    actionsOpened: actions.actionsOpened,
    actionsClosed: actions.actionsClosed,
    addBestAnswer: actions.addBestAnswer,
    removeBestAnswer: actions.removeBestAnswer,
    buttonClicked: data =>
      actions.buttonClicked({
        name: 'action_click',
        type: 'comment',
        ...data,
        categoryId: category._id,
        postId: post._id,
      }),
    markAsBestAnswerClicked: data =>
      actions.buttonClicked({
        name: 'mark_as_best_answer',
        origin: 'post_page',
        postId: post._id,
        ...data,
      }),
    navigateWithinForum: actions.navigateWithinForum,
    sectionUrl: getSectionUrl(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withPermissions,
  withDeviceType,
  withAuth,
  withExperiment({
    isNewQAKeysEnabled: EXPERIMENT_NEW_QA_KEYS,
    isPostPageRevampEnabled: EXPERIMENT_POSTPAGE_REVAMP,
  }),
)(CommentActions);
